import React from 'react';
import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import RootFolder from './views/folder/RootFolder';
import Risk from './views/risk/Risk';
import Folder from './views/folder/Folder';
import Settings from './views/settings/Settings';
import Tags from './views/settings/tags/Tags';
import Likelihoods from './views/settings/likelihood-scale/Likelihoods';
import ImpactScaleValues from './views/settings/impact-scale/ImpactValues';
import ImpactTargets from './views/settings/impact-targets/ImpactTargets';
import REALM_ROLE from '../constants/realm-role';
import SecureByRole from './SecureByRole';
import LeftMenu from './views/left-menu/LeftMenu';
import HelpMenu from './views/help-menu/HelpMenu';
import { KeycloakProvider } from './KeycloakContext';
import { HelpMenuProvider } from './HelpMenuContext';
import { SettingsProvider } from './SettingsContext';
import { FolderTreeProvider } from './FolderTreeContext';
import useTenantId from '../hooks/useTenantId';
import { NotificationProvider } from './NotificationsContext';

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

function Layout() {
  const tenantId = useTenantId();

  return (
    <KeycloakProvider tenantId={tenantId}>
      <NotificationProvider>
        <SecureByRole role={REALM_ROLE.RISKS_VIEWER}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <HelpMenuProvider>
              <SettingsProvider>
                <FolderTreeProvider>
                  <LeftMenu />
                  <Outlet />
                  <HelpMenu />
                </FolderTreeProvider>
              </SettingsProvider>
            </HelpMenuProvider>
          </StyleSheetManager>
        </SecureByRole>
      </NotificationProvider>
    </KeycloakProvider>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="folders/root" />} />
        <Route path="folders/root" element={<RootFolder />} />
        <Route path="folders/:id" element={<Folder />} />
        <Route path="risks/:id" element={<Risk />} />
        <Route
          path="settings"
          element={
            <SecureByRole role={REALM_ROLE.RISKS_ADMIN}>
              <Settings />
            </SecureByRole>
          }
        />
        <Route
          path="settings/tags"
          element={
            <SecureByRole role={REALM_ROLE.RISKS_ADMIN}>
              <Tags />
            </SecureByRole>
          }
        />
        <Route
          path="settings/likelihood-scale"
          element={
            <SecureByRole role={REALM_ROLE.RISKS_ADMIN}>
              <Likelihoods />
            </SecureByRole>
          }
        />
        <Route
          path="settings/impact-value-scale"
          element={
            <SecureByRole role={REALM_ROLE.RISKS_ADMIN}>
              <ImpactScaleValues />
            </SecureByRole>
          }
        />
        <Route
          path="settings/impact-targets"
          element={
            <SecureByRole role={REALM_ROLE.RISKS_ADMIN}>
              <ImpactTargets />
            </SecureByRole>
          }
        />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
