import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  mdiMenu,
  mdiHistory,
  mdiTrashCanOutline,
  mdiFolderMoveOutline,
  mdiContentCopy,
} from '@mdi/js';
import theme from '../../../styles/theme';
import useApi from '../../../hooks/useApi';
import IconButton from '../../common/buttons/IconButton';
import MainContentSpinner from '../../common/MainContentSpinner';
import RiskRevisionDateCard from './RiskRevisionDateCard';
import RiskStateCard from './RiskStateCard';
import RiskManagementStrategyCard from './RiskManagementStrategyCard';
import RiskPriorityCard from './RiskPriorityCard';
import RightMenu from './right-menu/RightMenu';
import Backdrop from '../../common/Backdrop';
import RiskForm from './RiskForm';
import RiskMagnitudes from './RiskMagnitudes';
import RiskNameInput from './RiskNameInput';
import { Header, HeaderTools, HeaderToolsDropdown } from '../header/Header';
import { DropdownItem, DropdownItemIcon } from '../../common/Dropdown';
import DeleteRiskModal from './DeleteRiskModal';
import Changelog from './changelog/Changelog';
import RiskCharts from './charts/RiskCharts';
import RenderByRightRole from '../../RenderByRightRole';
import RIGHT_ROLE from '../../../constants/right-role';
import { RightsProvider } from '../../RightsContext';
import { PageForbiddenAccess } from '../../ForbiddenAccess';
import { useFolderTree } from '../../FolderTreeContext';
import MoveInFolderTreeModal from '../../MoveInFolderTreeModal';
import { useSettings } from '../../SettingsContext';
import CopyRiskModal from './CopyRiskModal';
import CopyLinkDropdownItem from '../header/CopyLinkDropdownItem';

function Risk() {
  const { id } = useParams();
  const intl = useIntl();
  const { setSelectedFolder, openFoldersById } = useFolderTree();
  const [showRightMenuOnMobile, setShowRightMenuOnMobile] =
    React.useState(false);
  const [deleteRisk, setDeleteRisk] = React.useState(false);
  const [moveRisk, setMoveRisk] = React.useState(false);
  const [copyRisk, setCopyRisk] = React.useState(false);
  const [showChangelog, setShowChangelog] = React.useState(false);
  const [magnitudeHistory, setMagnitudeHistory] = React.useState();
  const { likelihoods, impactTargets, impactValues } = useSettings();
  const {
    response: risk,
    isLoading: isFetchingRisk,
    callEndpoint: fetchRisk,
    isForbidden,
  } = useApi({
    endpoint: `/api/v1/risks/${id}`,
    method: 'GET',
  });

  React.useEffect(() => {
    fetchRisk();
  }, [fetchRisk]);

  React.useEffect(() => {
    if (risk) {
      setMagnitudeHistory(risk.magnitudeHistory);
      setSelectedFolder((risk.folder && risk.folder.id) || 'root');
      openFoldersById(risk.breadcrumb.slice(0, -1).map((item) => item.id));
    }
  }, [risk, setSelectedFolder, openFoldersById]);

  if (isForbidden) {
    return <PageForbiddenAccess />;
  }

  return !isFetchingRisk && risk ? (
    <Wrapper>
      <RightsProvider rights={risk.folder.rights}>
        <RiskForm risk={risk} onPatchSuccess={setMagnitudeHistory}>
          <Header
            breadcrumbItems={[
              {
                id: 'risks',
                name: intl.formatMessage({ id: 'RISKS' }),
                link: '/folders/root',
              },
              ...risk.breadcrumb.map((item) => ({
                ...item,
                link: `/folders/${item.id}`,
              })),
              { id: risk.id, name: risk.name },
            ]}
          >
            <RiskNameInput />
            <HeaderTools>
              <ShowInformationButton
                tooltip={intl.formatMessage({ id: 'SHOW_INFORMATION' })}
                iconPath={mdiMenu}
                aria-label={intl.formatMessage({ id: 'SHOW_INFORMATION' })}
                onClick={() => setShowRightMenuOnMobile(!showRightMenuOnMobile)}
              ></ShowInformationButton>

              <HeaderToolsDropdown>
                <CopyLinkDropdownItem />
                <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
                  <DropdownItem role="button" onClick={() => setMoveRisk(true)}>
                    <DropdownItemIcon path={mdiFolderMoveOutline} />
                    <FormattedMessage id="MOVE_TO" />
                  </DropdownItem>
                  <DropdownItem role="button" onClick={() => setCopyRisk(true)}>
                    <DropdownItemIcon path={mdiContentCopy} />
                    <FormattedMessage id="COPY_RISK" />
                  </DropdownItem>
                </RenderByRightRole>
                <DropdownItem
                  role="button"
                  onClick={() => setShowChangelog(true)}
                >
                  <DropdownItemIcon path={mdiHistory} />
                  <FormattedMessage id="CHANGELOG" />
                </DropdownItem>
                <RenderByRightRole role={RIGHT_ROLE.MODIFIER}>
                  <DropdownItem
                    role="button"
                    onClick={() => setDeleteRisk(true)}
                  >
                    <DropdownItemIcon path={mdiTrashCanOutline} />
                    <FormattedMessage id="DELETE_RISK" />
                  </DropdownItem>
                </RenderByRightRole>
              </HeaderToolsDropdown>
            </HeaderTools>
          </Header>
          <RiskContent>
            <ContentWrapper>
              <CardContainer>
                <RiskStateCard />
                <RiskManagementStrategyCard />
                <RiskPriorityCard />
                <RiskRevisionDateCard />
              </CardContainer>
              {likelihoods && impactValues && impactTargets ? (
                <RiskCharts
                  magnitudeHistory={magnitudeHistory}
                  likelihoods={likelihoods}
                  impactValues={impactValues}
                  impactTargets={impactTargets}
                />
              ) : null}
              <RiskMagnitudes likelihoods={likelihoods} />
            </ContentWrapper>
          </RiskContent>
          <RightMenu
            onClose={() => setShowRightMenuOnMobile(false)}
            showRightMenuOnMobile={showRightMenuOnMobile}
          />
          {showRightMenuOnMobile ? (
            <RightMenuBackdrop
              onClick={() => setShowRightMenuOnMobile(false)}
            />
          ) : null}
          {deleteRisk ? (
            <DeleteRiskModal risk={risk} onClose={() => setDeleteRisk(false)} />
          ) : null}
          {moveRisk ? (
            <MoveInFolderTreeModal
              openedFolders={risk.breadcrumb.map((item) => item.id)}
              patchEndpoint={`/api/v1/risks/${risk.id}`}
              patchAttribute="folderId"
              onClose={() => setMoveRisk(false)}
              onSuccess={fetchRisk}
            />
          ) : null}
          {copyRisk ? (
            <CopyRiskModal risk={risk} onClose={() => setCopyRisk(false)} />
          ) : null}
          {showChangelog ? (
            <>
              <Changelog
                url={`/api/v1/risks/${risk.id}/changelog`}
                onClose={() => setShowChangelog(false)}
              />
              <Backdrop onClick={() => setShowChangelog(false)} />
            </>
          ) : null}
        </RiskForm>
      </RightsProvider>
    </Wrapper>
  ) : (
    <MainContentSpinner size={theme.arter.icon.size.medium} />
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  align-content: flex-start;

  @media ${(props) => props.theme.arter.device.pad} {
    position: relative;
  }
`;

const ShowInformationButton = styled(IconButton)`
  background-color: ${(props) => props.theme.arter.color.veryLightGray};

  @media ${(props) => props.theme.arter.device.desktopMedium} {
    display: none;
  }
`;

const RiskContent = styled.section`
  position: relative;
  overflow: auto;
  height: calc(100% - 133px);
  flex-grow: 1;
  z-index: 1;
  padding: ${(props) => props.theme.arter.spacing.mediumLarge};
  padding-bottom: ${(props) => props.theme.arter.spacing.extraLarge};

  @media ${(props) => props.theme.arter.device.desktop} {
    height: calc(100% - 87px);
  }

  @media ${(props) => props.theme.arter.device.desktopLarge} {
    padding: ${(props) => props.theme.arter.spacing.large};
  }
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  min-width: 380px;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${(props) =>
    `${props.theme.arter.spacing.large} ${props.theme.arter.spacing.medium}`};
  border: 1px solid ${(props) => props.theme.arter.color.lightGray};
  border-radius: ${(props) => props.theme.arter.border.radius.medium};

  @media ${(props) => props.theme.arter.device.pad} {
    padding: 0;
    border: none;
    border-radius: ${(props) => props.theme.arter.border.radius.medium};
  }
`;

const RightMenuBackdrop = styled(Backdrop)`
  @media ${(props) => props.theme.arter.device.desktopMedium} {
    display: none;
  }
`;

export default Risk;
